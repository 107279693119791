<script setup lang="ts">
const props = defineProps<{
  logo: string
}>()

const logo = ref('')

watchEffect(async () => {
  try {
    const importLogo = import.meta.glob('assets/logos/**/**.svg', { as: 'raw', eager: false })
    logo.value = await importLogo[`/assets/logos/${props.logo}.svg`]()
  } catch {
    console.error(`Logo ${props.logo} not found in assets/logos`)
  }
})
</script>

<template>
  <span class="n-logo" v-html="logo" />
</template>

<style>
.n-logo svg {
  height: 1em;
  width: auto;
  vertical-align: middle;
  /*margin-bottom: 0.125em;*/
}
</style>
